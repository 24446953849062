import './App.css';

import React, { useState, useEffect } from 'react';

import { initializeApp } from "firebase/app";
import { initializeAppCheck, getToken, ReCaptchaV3Provider } from "firebase/app-check"

import { getAnalytics } from "firebase/analytics";

function App() {
  const [token, setToken] = useState('')



  const getTokenData = async () => {

    const firebaseConfig = {
      apiKey: "AIzaSyDqGlS8V3T_lDpx3vdxhKoZr6KzmWvyNLY",
      authDomain: "dev-flocco.firebaseapp.com",
      databaseURL: "https://dev-flocco.firebaseio.com",
      projectId: "dev-flocco",
      storageBucket: "dev-flocco.appspot.com",
      messagingSenderId: "75782306926",
      appId: "1:433883780127:android:c10cab86c282804918b12a",
      measurementId: "G-QEJR3RQ4M8"
    };


    // Initialize Firebase

    const app = initializeApp(firebaseConfig);

    const appcheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider('6Lf56EQhAAAAAABH4Q-LFHkmBjTPvah7z754Xp3f'),
      isTokenAutoRefreshEnabled: true
    });

    const token = await getToken(appcheck)

    console.log(token.token)
    navigator.clipboard.writeText(token.token);
    alert("copied token to clipboard");

  }

  return (
    <div className="App">
      <header className="App-header">
       <button onClick={getTokenData}>Generate Token</button>
      </header>
    </div>
  );
}

export default App;
